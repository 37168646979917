import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"

import ArticleLayout from "../layouts/articleLayout"
import SEO from "../components/seo"
import LinkTracker from "../components/linkTracker"
import Script from "../components/Script"

const shortcodes = {
  LinkTracker,
  Script,
  Link,
}

const DefaultTemplate = (data) => {
  const { title } = data.pageContext.frontmatter
  return (
    <ArticleLayout>
      <SEO title={title} />
      <div className="u-mb+">
        <MDXProvider components={shortcodes}>{data.children}</MDXProvider>
      </div>
    </ArticleLayout>
  )
}

export default DefaultTemplate

// export const pageQuery = graphql`
//   query {
//     allMdx {
//       edges {
//         node {
//           frontmatter {
//             slug
//           }
//         }
//       }
//     }
//   }
// `
