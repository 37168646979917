import PropTypes from "prop-types"
import React from "react"
// import typewriter from "../../analytics"

function LinkTracker(props) {
  function handleClick(e, vals) {
    const url = e.target.href
    e.preventDefault()
    window.analytics && window.analytics.initialized
      ? window.analytics.track(props.eventName, vals)
      : null
    setTimeout(() => {
      window.location = url
    }, 300)
  }

  return (
    <a
      onClick={e => {
        handleClick(e, props.trackProps)
      }}
      href={props.url}
    >
      {props.children}
    </a>
  )
}

LinkTracker.propTypes = {
  eventName: PropTypes.string.isRequired,
  trackProps: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
}

export default LinkTracker
