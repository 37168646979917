import PropTypes from "prop-types"
import React from "react"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import Nav from "./nav"

const Header = ({ siteMetadata, headerImg }) => (
  <header id="" className={headerImg ? null : `hero text-center`}>
    {headerImg && (
      <>
        <Nav fixed />
        <Img image={headerImg.childImageSharp.gatsbyImageData} />
      </>
    )}
    {siteMetadata && (
      <>
        <h1>{siteMetadata.title}</h1>
        <h3>{siteMetadata.description}</h3>
      </>
    )}
  </header>
)

Header.propTypes = {
  siteMetadata: PropTypes.object,
}

Header.defaultProps = {
  siteMetadata: {
    title: ``,
    description: ``,
  },
}

export default Header
