// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cdt-download-mdx": () => import("./../../../src/pages/cdt-download.mdx" /* webpackChunkName: "component---src-pages-cdt-download-mdx" */),
  "component---src-pages-cdt-optin-mdx": () => import("./../../../src/pages/cdt-optin.mdx" /* webpackChunkName: "component---src-pages-cdt-optin-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-optin-confirmation-mdx": () => import("./../../../src/pages/optin-confirmation.mdx" /* webpackChunkName: "component---src-pages-optin-confirmation-mdx" */),
  "component---src-pages-optin-mdx": () => import("./../../../src/pages/optin.mdx" /* webpackChunkName: "component---src-pages-optin-mdx" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-resources-template-js": () => import("./../../../src/templates/resourcesTemplate.js" /* webpackChunkName: "component---src-templates-resources-template-js" */)
}

