import { useLayoutEffect } from "react"

const useScript = (url, elem, uid, async) => {
  useLayoutEffect(
    () => {
      const script = document.createElement(`script`)
      const placement = document.querySelector(elem)
      script.src = url
      script.async = typeof async === `undefined` ? true : async
      script.setAttribute(`data-uid`, uid)
      placement.appendChild(script)
      // return () => {
      //   // clean-up function
      //   placement.removeChild(script)
      // }
    }
    // [ elem ]
  )
}

export default function Script({ src, elem, uid, async = true }) {
  useScript(src, elem, uid, async)
  return null // Return null is necessary for the moment.
}
