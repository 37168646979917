import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LinkTracker = makeShortcode("LinkTracker");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <hr></hr>
    <p>
  <em>
  Do you know someone who might benefit from this {props.frontmatter.resource_name}? You can <LinkTracker url={props.frontmatter.email_url_this_page + "\n" + props.frontmatter.url_to_this_page} trackProps={props.trackProps} eventName={'Resource Shared'} mdxType="LinkTracker">
      share it
    </LinkTracker>.
  </em>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      