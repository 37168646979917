import React from "react"
import PropTypes from "prop-types"
import Nav from "../components/nav"
import Header from "../components/header"

const ArticleLayout = ({ headerImg, children }) => (
  <>
    {headerImg && <Header headerImg={headerImg} />}
    {!headerImg && <Nav />}
    <div className="container">
      <div className="row">
        <div className="columns medium-12 large-6 large-centered">
          <article>
            {/* <main> */}
            {children}
            {/* </main> */}
          </article>
        </div>
      </div>
    </div>
  </>
)

ArticleLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ArticleLayout
