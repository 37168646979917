import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.svg"

const Nav = ({ fixed }) => {
  const styles = {
    position: `absolute`,
    top: 0,
    left: 0,
  }
  return (
    <>
      <nav className="u-m-" role="navigation" style={fixed ? styles : null}>
        <Link to="/">
          <img src={logo} alt="Home" />
        </Link>
      </nav>
      {/* <ul className="main-navigation">
      <li>
        <a href="/">
          <span
            className="icon-ms-logo"
            aria-hidden="true"
            data-icon="&#xe000;"
          />
        </a>
      </li>
      <li>
        <a href="/about">me</a>
      </li>
      <li>
        <a id="work" href="http://work.michaelsheedy.com">
          work
        </a>
      </li>
      <li>
        <a href="/posts">posts</a>
      </li>
      <li>
        <a href="/library">library</a>
      </li>
    </ul> */}
    </>
  )
}

export default Nav
