/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

function SEO({
  title,
  description,
  lang,
  meta,
  image,
  article,
  fbTwitterImg,
  linkedInImg,
  linkedInImgSml,
}) {
  const { pathname } = useLocation()
  const {
    site,
    ogImageDefault,
    ogImageDefaultLinkedIn,
    ogImageDefaultLinkedInSml,
  } = useStaticQuery(query)
  const defaultImage = getSrc(ogImageDefault)
  const defaultImageLinkedIn = getSrc(ogImageDefaultLinkedIn)
  const defaultImageLinkedInSml = getSrc(ogImageDefaultLinkedInSml)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    twitterUsername,
    author,
  } = site.siteMetadata

  // const metaDescription = description || site.siteMetadata.description
  const seo = {
    title: `${title} | ${author}` || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    image: image ? `${siteUrl}${fbTwitterImg}` : `${siteUrl}${defaultImage}`,
    type: article ? `article` : `website`,
    fbTwitterImg: fbTwitterImg
      ? `${siteUrl}${fbTwitterImg}`
      : `${siteUrl}${defaultImage}`,
    linkedInImg: linkedInImg
      ? `${siteUrl}${linkedInImg}`
      : `${siteUrl}${defaultImageLinkedIn}`,
    linkedInImgSml: linkedInImgSml
      ? `${siteUrl}${linkedInImgSml}`
      : `${siteUrl}${defaultImageLinkedInSml}`,
    twitterCard: image ? `summary_large_image` : `summary`,
    twitterSite: twitterUsername,
    twitterCreator: twitterUsername,
    siteName: author,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          name: `author`,
          content: seo.siteName,
        },
        {
          property: `article:author`,
          content: seo.siteName,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:type`,
          content: seo.type,
        },
        {
          name: `twitter:card`,
          content: seo.twitterCard,
        },
        {
          name: `twitter:creator`,
          content: seo.twitterCreator,
        },
        {
          name: `twitter:site`,
          content: seo.twitterSite,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          name: `twitter:image`,
          content: seo.fbTwitterImg,
        },
        {
          name: `twitter:image:alt`,
          content: seo.title,
        },
        // {
        //   name: `og:image`,
        //   content: seo.linkedInImg, // 180x110 Image for Linkedin
        // },
        // {
        //   name: `og:image:width`,
        //   content: `180`,
        // },
        // {
        //   name: `og:image:height`,
        //   content: `110`,
        // },
        // {
        //   property: `og:image`,
        //   content: seo.fbTwitterImg, // 600x315 Image for Facebook
        // },
        // {
        //   property: `og:image:width`,
        //   content: `600`,
        // },
        // {
        //   property: `og:image:height`,
        //   content: `314`,
        // },
        // Main Image tag
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          property: `og:image:secure_url`,
          content: seo.image,
        },
        {
          property: `og:image:width`,
          content: defaultImage.width,
        },
        {
          property: `og:image:height`,
          content: defaultImage.height,
        },
        // LinkedIn
        {
          property: `og:image`,
          content: seo.linkedInImg,
        },
        {
          property: `og:image:secure_url`,
          content: seo.linkedInImg,
        },
        {
          property: `og:image:width`,
          content: defaultImageLinkedIn.width,
        },
        {
          property: `og:image:height`,
          content: defaultImageLinkedIn.height,
        },
        // LinkedIn Small
        {
          property: `og:image`,
          content: seo.linkedInImgSml,
        },
        {
          property: `og:image:secure_url`,
          content: seo.linkedInImgSml,
        },
        {
          property: `og:image:width`,
          content: defaultImageLinkedInSml.width,
        },
        {
          property: `og:image:height`,
          content: defaultImageLinkedInSml.height,
        },
        {
          name: `image`,
          content: seo.image,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: {},
  description: null,
  image: null,
  article: false,
  fbTwitterImg: null,
  linkedInImg: null,
  linkedInImgSml: null,
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.object,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.bool,
  article: PropTypes.bool,
  fbTwitterImg: PropTypes.string,
  linkedInImg: PropTypes.string,
  linkedInImgSml: PropTypes.string,
}

export default SEO

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        author
        titleTemplate
        defaultDescription: description
        siteUrl: url
        # defaultImage: image
        twitterUsername: twitter
      }
    }
    ogImageDefault: file(name: { eq: "site-image" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1200, height: 630)
      }
    }
    ogImageDefaultLinkedIn: file(name: { eq: "site-image" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1200, height: 627)
      }
    }
    ogImageDefaultLinkedInSml: file(name: { eq: "site-image" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 180, height: 110)
      }
    }
  }
`
